import {Component, Input} from '@angular/core'
import {LockService} from '../../services/lock.service'
import {MatIcon} from '@angular/material/icon'
import {AsyncPipe} from '@angular/common'
import {LockPipe} from '../../pipes/lock.pipe'

@Component({
  selector: 'kdl-lock',
  templateUrl: './lock.component.html',
  styleUrl: './lock.component.scss',
  imports: [
    MatIcon,
    AsyncPipe,
    LockPipe
  ]
})
export class LockComponent {
  @Input() id = ''

  constructor(public lockService: LockService) {
  }
}
