import {Pipe, PipeTransform} from '@angular/core'
import {IItemLock} from '@kdl/kulladal-product-backend'

@Pipe({
    name: 'lock',
    standalone: true
})
export class LockPipe implements PipeTransform {
  transform(locks: IItemLock[], id: string): boolean {
    return locks.some(l => l.id === id)
  }
}
